<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
<!-- <app-loader v-if="isLoading"></app-loader> -->
<modal
  :title="modalTitle"
  :handler="true"
  :isLoading="isLoading"
  :cssClass="'modal-xxl max-height-100-145 modal-table-scroll'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <p class="text-start font-14 text-color-2 mb-4">
      <strong class="heading-color">Information:</strong>{{
      infoMessageObject[accessType]}}.
    </p>
    <div>
      <Grid
        class="select-audience-modal-table table-design-2 th-td-checkbox-style-2"
        ref="grid"
        :data-items="membersData?.items"
        :skip="skip"
        :take="take"
        :total="total"
        :pageable="gridPageable"
        :selectable="true"
        :selected-field="selectedField"
        :columns="columns"
        @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange"
        @headerselectionchange="onHeaderSelectionChange"
      >
        <toolbar>
          <div
            class="d-flex flex-wrap justify-content-between w-100 align-items-center"
          >
            <checkbox
              :class="'w-100-vsm'"
              v-model="forceSelectAll"
              :default-checked="false"
              :label="selectAllLabelGenerator()"
            />
            <checkbox
              :class="'w-100-vsm'"
              v-if="accessType == 1"
              :value="value"
              @change="withoutDigitalPass"
              :label="'Only display members without digital pass'"
            />

            <div class="search-box-wrap width-220 w-100-vsm mt-2 mt-sm-0">
              <span class="k-icon k-i-zoom"></span>
              <k-input
                :class="'mb-0 w-100'"
                placeholder="Search"
                :value="searchWord"
                @input="onSearch"
              ></k-input>
            </div>
          </div>
        </toolbar>

        <template v-slot:contactTemplate="{ props }">
          <td>
            <contact
              v-if="props.rowType !== 'groupHeader'"
              :data-item="props.dataItem"
            ></contact>
          </td>
        </template>

        <GridNoRecords> No audiences available. </GridNoRecords>
      </Grid>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <kbutton @click="modalHandler" :class="'ms-2'">Cancel</kbutton>
      <kbutton
        v-if="forceSelectAll"
        :disabled="membersData?.items?.length === 0 "
        :theme-color="'primary'"
        :class="'ms-2'"
        @click="addMemberHandler"
        >Add Audience
      </kbutton>
      <kbutton
        v-if="!forceSelectAll"
        :disabled="selectedItems.length === 0"
        :theme-color="'primary'"
        :class="'ms-2'"
        @click="addMemberHandler"
      >
        Add Audience</kbutton
      >
    </div>
  </template>
</modal>
