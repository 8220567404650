<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
<!-- <app-loader v-if="isLoading"></app-loader> -->
<modal
  title="Select An Audience List"
  :handler="isModalVisible"
  :cssClass="'modal-md-3'"
  @modalHandler="modalHandler"
  :isLoading="isLoading"
>
  <template v-slot:content>
    <p class="text-start font-14 text-color-2 mb-4">
      <strong class="heading-color">Information:</strong>{{
      infoMessageObject[accessType]}}.
    </p>
    <div class="mb-3">
      <Grid
        class="first-col-checkbox-center select-audience-list-table table-design-2 th-td-checkbox-style-2 max-height-100-440 min-height-150"
        ref="grid"
        :data-items="membersData?.items"
        :skip="skip"
        :take="take"
        :total="total"
        :pageable="gridPageable"
        :selectable="true"
        :selected-field="selectedField"
        :columns="columns"
        @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange"
        @headerselectionchange="onHeaderSelectionChange"
      >
        <toolbar>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <!-- <checkbox v-model="forceSelectAll" :default-checked="false" :label="' Force select all'" /> -->

            <div class="search-box-wrap width-220 w-100-sm">
              <span class="k-icon k-i-zoom"></span>
              <k-input
                :class="'mb-0 w-100'"
                placeholder="Search by List Name"
                @input="debounceSearch"
              >
              </k-input>
            </div>
          </div>
        </toolbar>

        <template v-slot:contactTemplate="{ props }">
          <td>
            <contact
              v-if="props.rowType !== 'groupHeader'"
              :data-item="props.dataItem"
            ></contact>
          </td>
        </template>

        <GridNoRecords> No audience list found. </GridNoRecords>
      </Grid>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
      </div>
      <div class="me-1">
        <kbutton
          v-if="forceSelectAll"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addMemberHandler"
        >
          Add Members</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton
          v-if="!forceSelectAll"
          :disabled="!selectedItems.length"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addMemberHandler"
        >
          Done</kbutton
        >
      </div>
    </div>
  </template>
</modal>
