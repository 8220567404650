export default class RecipientsModel {
    public email: string = ''
    public emailStatus: string ='' 
    public errors: any = []
    public id: number = 0
    public name: string = ''
    public objectId: number = 0
    public order: number = 0
    public passStatus: string = ''
    public propertyValues: any = null
    public selected: boolean =  false

}