<template src="./SelectMemberModal.html"></template>
<style lang="scss">
@import "./SelectMemberModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../../common/AppLoader/AppLoader.vue";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import modal from "../../../common/AppModal/AppModal.vue";
import UploadDataForDigtalPassModal from "../uploadDataForDigtalPassModal/UploadDataForDigtalPassModal.vue";
import GetMembersModel from "../../../../models/member/getMembersModel";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import { addMembersToTemplateService } from "../../../../services/clubDetails/manageRecipients.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import { addMembersToVoucherCampaignService } from "@/services/clubDetails/voucherManageRecipients.service";
export default {
  created() {},
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    checkbox: Checkbox,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    modal,
    UploadDataForDigtalPassModal: UploadDataForDigtalPassModal,
    AppSnackBar,
    GridNoRecords,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      rejectedItems: [],
      importModalVisible: false,
      visibleDialog: true,
      finalItems: [],
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
      forceSelectAll: false,
      allChecked: false,
      timer: null,
      modalTitle: null,
      value: false,
      accessType: this.$route.query.type,
      infoMessageObject: {
        1: " All audiences from the selected list will be added as recipients for your digital pass",
        2: " All audiences from the selected list will be added as recipients for your email campaign",
        3: " All members selected from here will be added as recipients for your voucher program. You must generate voucher code for these members before sending email",
      },
    };
  },

  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length == this.membersData?.items?.length &&
        this.membersData?.items?.length != 0
      );
    },

    columns() {
      let modifiedColumnData = [];
      var constField = [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
        },
        {
          field: "email",
          title: "Email",
        },
        {
          field: "firstName",
          title: "First Name",
        },
        {
          field: "lastName",
          title: "Last Name",
        },
        {
          field: "mobile",
          title: "Phone Number",
        },
      ];
      let columnsData = this.fieldData;
      modifiedColumnData.push(...constField);

      for (const i in columnsData) {
        if (columnsData[i].isVisible) {
          let object = {
            field: columnsData[i].label,
            title: columnsData[i].label,
          };
          modifiedColumnData.push(object);
        }
      }

      return modifiedColumnData;
    },
    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.allAudience.audiences.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].userId) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      } else {
        let membersData = this.$store.state.allAudience.audiences.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].userId) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      // this.selectedItems = [];
      return this.membersData ? this.membersData.offset : 0;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    isLoading() {
      return this.$store.state.allAudience.isLoading;
    },
  },
  mounted() {
    this.$store.commit("SET_ALL_AUDIENCE", []);
    this.getMembers();
    this.modalTitle =
      +this.$route.query.type == 3
        ? "Select Audience for Voucher"
        : "Select Audience for Digital Pass";
  },
  unmounted() {
    this.$store.commit("SET_ALL_AUDIENCE", []);
  },
  methods: {
    selectAllLabelGenerator() {
      return this.forceSelectAll
        ? `Select All (${this.membersData?.size - this.rejectedItems?.length})`
        : "Select All (0)";
    },
    withoutDigitalPass(e) {
      this.value = e.value;
      let payload = {
        clubId: this.clubId,
        searchQuery: "",
        entityType: +this.$route.query.type,
        exceptTemplateId: this.$route.query.template_id,
        exceptVoucherCampaignId: this.$route.query.voucherCampaignId,
        NoPassYet: e.value ? e.value : null,
      };
      this.$store.dispatch("getAudience", payload);
    },
    getMembers() {
      let payload = {
        clubId: this.clubId,
        limit: 10,
        offset: 0,
        searchQuery: "",
        entityType: +this.$route.query.type,
        exceptTemplateId: this.$route.query.template_id,
        exceptVoucherCampaignId: this.$route.query.voucherCampaignId,
      };
      this.$store.dispatch("getAudience", payload);
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].userId) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].userId);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].userId) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].userId) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    addMemberHandler() {
      if (+this.$route.query.type == 3) {
        this.addMembersForVoucherCampaign();
      } else {
        this.addMembersForDigitalpass();
      }
    },
    addMembersForDigitalpass() {
      var payload = {
        clubId: this.$route.params.id,
        templateId: +this.$route.query.template_id,
        isAll: this.forceSelectAll,
        userIds: this.selectedItems,
        exceptUserIds: this.rejectedItems,
      };

      this.$store.commit("LOADING_STATUS", true);
      var payloadGetResipients = {
        clubId: payload.clubId,
        templateId: payload.templateId,
      };
      addMembersToTemplateService(payload)
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "New audiences added for digital pass.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          this.$store.dispatch("getRecipients", payloadGetResipients);
        })
        .catch((error) => {});
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
    },
    addMembersForVoucherCampaign() {
      let payload = {
        clubId: this.$route.params.id,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
        isAll: this.forceSelectAll,
        userIds: this.selectedItems,
        exceptUserIds: this.rejectedItems,
      };

      this.$store.commit("LOADING_STATUS", true);
      const payloadForGetRecipients = {
        clubId: payload.clubId,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
      };
      addMembersToVoucherCampaignService(payload)
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          // this.$store.commit("SET_SHOW_MESSAGE", true);
          const model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "New audiences added for this voucher program.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          this.$store.dispatch("getVoucherRecipients", payloadForGetRecipients);
        })
        .catch((error) => {});

      // this.$store.dispatch("addMembersToTemplate", payload);
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
      // this.showNotification("New members added to the digital recipients list.", "warning");

      // this.$router.push({
      //   name: "AddRecipients",
      //   query: {
      //     template_id: this.$route.query.template_id,
      //   },
      // });
    },
    modalHandler(data) {
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
    },

    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.userId);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.userId) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.userId) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.userId);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.userId) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.userId) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
        // this.selectedItems = event.dataItems
        //   .filter((x) => x.selected === true)
        //   .map((x) => x.id);
        // if (event.dataItem[this.selectedField]) {
        //   this.finalItems = this.finalItems.concat(event.dataItem);
        // }
        // if (!event.dataItem[this.selectedField]) {
        //   this.finalItems = this.finalItems.filter(
        //     (x) => x.id !== event.dataItem.id
        //   );
        // }
      }
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);
      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.limit = this.take;
      model.entityType = this.$route.query.type;
      model.searchQuery = this.searchWord;
      model.exceptTemplateId = this.$route.query.template_id;
      model.exceptVoucherCampaignId = this.$route.query.voucherCampaignId;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getAudience", model);
      }, 800);
    },
    // dataStateChange: function (event) {
    //   this.allChecked = false;
    //   this.take = event.data.take;
    //   this.skip = event.data.skip;

    //   let payload = new GetMembersModel();
    //   payload.clubId = this.clubId;
    //   payload.limit = event.data.take;
    //   payload.offset = event.data.skip;
    //   payload.searchText = this.searchWord;

    //   this.$store.dispatch("getMembers", payload);
    // },

    dataStateChange: function (event) {
      this.allChecked = false;
      this.take = event.data.take;
      this.skip = event.data.skip;

      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = this.searchWord;
      model.limit = event.data.take;
      model.offset = event.data.skip;
      model.exceptTemplateId = this.$route.query.template_id;
      this.$store.dispatch("getAudience", model);
    },

    deleteMember() {
      let payload = {
        clubId: this.$props.id,
        memberIds: this.selectedItems,
      };
      this.$store.dispatch("deleteMembers", payload);
      this.selectedItems = [];
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
};
</script>

<style></style>
