<modal
        :class="'max-height-450'"
  title="Cannot Send Digital Pass"
  :handler="true"
  :cssClass="'c-modal-small'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-2 text-center">
        Please import data for following users: {{memberList}}
      </p>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-between">
      <kbutton @click="modalHandler" :class="'w-100'">Close</kbutton>
    </div>
  </template>
</modal>