export default class PassMapModel {
    public column: string = ""
    public mapFrom: string = ""
}


export class CodeNameModel {
    public name: string = ""
    public code: string = ""
}
