<!-- <h1>{{template_id}}</h1> -->
<div class="dashboard-right-inner">
  <!--breadcrumb-->
  <div class="breadcrumb" v-if="isBreadCrumb">
    <a href="#">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.0025 2.38854C10.4323 1.87627 9.56767 1.87626 8.9975 2.38854L3.4975 7.33008C3.18086 7.61457 3 8.0202 3 8.44587V15.5C3 16.3284 3.67157 17 4.5 17H6.5C7.32843 17 8 16.3284 8 15.5V11.5C8 11.2239 8.22386 11 8.5 11H11.5C11.7761 11 12 11.2239 12 11.5V15.5C12 16.3284 12.6716 17 13.5 17H15.5C16.3284 17 17 16.3284 17 15.5V8.44587C17 8.0202 16.8191 7.61457 16.5025 7.33008L11.0025 2.38854Z"
          fill="#6C707D" />
      </svg>
    </a>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.93934 4.06066L8.87872 8.00003L4.93934 11.9394L6 13L11 8.00003L6 3L4.93934 4.06066Z" fill="#ADB2BD" />
    </svg>
    <a class="color-sky-gray" href="#">FC Brisbane Warriors</a>
  </div>

  <div class="dashboard-content bg-white min-h-100vh--190">
    <div v-if="isBreadCrumb" class="d-flex justify-content-between align-items-center pb-3">
      <div>
        <h2 class="font-28 font-34-sm-big font-w-300">Manage Recipients</h2>
        <p class="font-14 color-light2">
          Set-up your digital pass template information for club audiences
        </p>
      </div>

      <div>
        <kbutton :fill-mode="'flat'">Cancel</kbutton>
        <kbutton :disabled="!hasEditAccess()" :icon="'edit'">Edit Pass</kbutton>
      </div>
    </div>

    <div>
      <Grid ref="grid" :class="'first-col-checkbox-center digital-pass-manage-recipients-table'"
        :data-items="membersData.items" :take="take" :skip="skip" :total="total" :pageable="gridPageable"
        :selectable="true" :selected-field="selectedField" :columns="columns" @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange" @headerselectionchange="onHeaderSelectionChange">
        <template v-slot:passCell="{props}">
          <td>
            <!-- <span v-if="" class="status-dot bg-secondary2"></span> -->
            <span v-if="props.dataItem.passStatus == 'Generated'" class="status-dot bg-success"></span>
            <span v-if="props.dataItem.passStatus == 'Not Generated'" class="status-dot bg-danger"></span>
            {{props.dataItem.passStatus}}
          </td>
        </template>
        <template v-slot:emailCell="{props}">
          <td>
            <span v-if="props.dataItem.emailStatus == 'Sent'" class="k-icon k-i-check color-success"></span>
            <span v-if="props.dataItem.emailStatus == 'Not Sent'" class="k-icon k-i-close color-danger"></span>

            <span class="ps-2">{{props.dataItem.emailStatus}}</span>
          </td>
        </template>

        <GridNoRecords> No members found. </GridNoRecords>

        <toolbar>
          <div class="search-box-wrap width-220 w-100-sm me-3 mr-0-sm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input :class="'mb-0 width-220 w-100-sm'" placeholder="Search" :value="searchWord"
                @input="onSearch"></k-input>
            </span>
          </div>

          <div class="pe-3 pr-0-sm w-100-sm flex-wrap flex-sm-wrap flex-md-nowrap">
            <label class="pe-2 mb-0 w-100-sm">Pass Status</label>
            <dropdownlist :class="'w-100-sm w-170'" :style="{  }" :data-items="passStatuses" :text-field="'text'"
              :data-item-key="'id'" :value="passStatusValue" @change="handlePassStatusValue">
            </dropdownlist>
          </div>

          <div class="pe-3 pr-0-sm w-100-sm flex-wrap flex-sm-wrap flex-md-nowrap">
            <label class="pe-2 mb-0 w-100-sm">Email Status</label>
            <dropdownlist :class="'w-100-sm'" :disabled="passStatusValue.text=='Generated'" :style="{  }"
              :data-items="emailStatuses" :text-field="'text'" :data-item-key="'id'" :value="emailStatusValue"
              @change="handleEmailStatusValue">
            </dropdownlist>
          </div>

          <div class="ms-auto ml-unset-1450 mt-2 mt-md-0 me-md-0 w-100-sm flex-wrap">
            <div v-if="selectedItems.length == 0" class="ms-0 ms-md-auto me-sm-auto me-md-0">
              <div class="d-flex">
                <buttongroup class="me-3">
                  <kbutton :disabled="!hasCreateAccess()" :theme-color="'primary'" @click="openModalForAddAudienceList">
                    Add Audience List
                  </kbutton>
                  <DropDownButton :disabled="!hasCreateAccess()" :class="'ml--2 bgdark-blue btn-size-38'"
                    :popup-settings="popupSettings" :theme-color="'primary'" :items="addAudienceDropDown"
                    :icon="'arrow-60-down'" @itemclick="onItemClick" />
                </buttongroup>

                <AppPopUp v-if="hasCreateAccess()" :class="'btn-dot-light h-38'" :items="moreOptionDropdownitems"
                  @dropId="getDropId" :icon="'more-vertical'" :buttonClass="'btn-clear'" :popUpSettings="popupSettings">
                </AppPopUp>
              </div>
            </div>

            <div v-if="selectedItems.length > 0" class="w-100-sm mb-2 mb-md-0">
              <span>
                <kbutton :disabled="!hasDeleteAccess()" @click="deleteRecipients" :class="'me-3 mr-0-sm w-100-sm'"
                  :icon="'delete'">Delete</kbutton>
              </span>
            </div>

            <div v-if="selectedItems.length > 0" class="w-100-sm">
              <span>
                <kbutton :disabled="!hasCreateAccess()" @click="sendRecipients" :theme-color="'primary'" :class="'me-1 mr-0-sm w-100-sm'">Send Digital
                  Pass
                </kbutton>
              </span>
            </div>
          </div>
        </toolbar>
      </Grid>

      <UploadDataForDigtalPassModal v-if="isUploadModalOpen" @closeUploadDataModal="closeUploadDataModal"
        @callBackDataForDigitalPass="openMapDataModal" />
      <MapDataToDigitalPassModal v-if="isMapDataModalOpen" :documentId="documentIdPass" @closeMapModal="closeMapModal"
        @closeAllModals="closeAllModals" />
      <SelectMemberModal v-if="addFromClubIsSelectModalOpen" @selectedUserIds="selectedMembersList($event)"
        @callBackSelectMemberAdd="addFromClubOpenUploadDataModal"
        @closeSelectMemberModalAdd="addFromClubcloseSelectMemberModal" />
      <SelectAnAudienceListModal v-if="isModalVisible" />
      <UploadDataForDigtalPassModal v-if="addFromClubisUploadModalOpen"
        @callBackDataForDigitalPassAdd="addFromClubopenMapDataModal"
        @callBackDataForDigitalPassDocId="callBackDataForDigitalPassDocId"
        @closeUploadDataModalAdd="addFromClubcloseUploadDataModal" />
      <MapDataToDigitalPassModal v-if="addFromClubisMapDataModalOpen" :documentId="documentIdPass"
        :userIds="selectedMembers" @closeAllModalsAdd="addFromClubcloseAllModals"
        @closeMapModalAdd="addFromClubcloseMapModal" />

      <RecipientsMemberDetails v-if="isViewDetailsModalOpen" @closeMemberDetailsModal="closeMemberDetailsModal">
      </RecipientsMemberDetails>

      <DataUnavailableUserModal v-if="isCkUserDataAvailableModalOpen"
        @closeDataUnavailableUserModal="closeDataUnavailableUserModal" :users="selectedMemberList">
      </DataUnavailableUserModal>

      <ManageRecipientsDeleteModal @digitalPassDeleteSuccess="digitalPassDeleteSuccess" v-if="isDeleteOpen"
        :selectedItems="selectedItems">
      </ManageRecipientsDeleteModal>
    </div>
  </div>
</div>