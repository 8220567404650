<template src="./SelectAnAudienceListModal.html"></template>
<style lang="scss">
@import "./SelectAnAudienceListModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { addListMembersToTemplateService } from "@/services/clubDetails/manageRecipients.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { addListMembersToVoucherCampaignService } from "@/services/clubDetails/voucherManageRecipients.service";
import AppLoader from "../AppLoader/AppLoader.vue";
import modal from "../AppModal/AppModal.vue";
import AppSnackBar from "../AppSnackBar/AppSnackBar.vue";
import UploadDataForDigtalPassModal from "@/components/clubDetails/digitalPass/uploadDataForDigtalPassModal/UploadDataForDigtalPassModal.vue";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    checkbox: Checkbox,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    modal,
    UploadDataForDigtalPassModal: UploadDataForDigtalPassModal,
    AppSnackBar,
    GridNoRecords,
  },
  props: {
    onConfirm: { type: Function },
  },
  data() {
    return {
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      gridPageable: false,
      selectedItems: [],
      rejectedItems: [],
      importModalVisible: false,
      visibleDialog: true,
      finalItems: [],
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
      forceSelectAll: false,
      allChecked: false,
      timer: null,
      isOnSearching: false,
      accessType: this.$route.query.type,
      infoMessageObject: {
        1: " All audiences from the selected list will be added as recipients for your digital pass",
        2: " All audiences from the selected list will be added as recipients for your email campaign",
        3: " All members from the selected list will be added as recipients for your voucher program. You must generate voucher code for these members before sending email",
      },
    };
  },

  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length == this.membersData?.items?.length &&
        this.membersData?.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
        },
        {
          field: "name",
          title: "List Name",
        },
        {
          field: "audienceCount",
          title: "Total Audience",
        },
      ];
    },
    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.list.lists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }

        return this.$store.state.list.lists;
      } else {
        let membersData = this.$store.state.list.lists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.list.lists;
        ;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      return this.membersData ? this.membersData.offset : 0;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isLoading() {
      return this.$store.state.common.loader;
    },
    fetchController() {
      return this.$store.state.list.fetchController;
    },
  },
  mounted() {
    this.getLists();
  },
  unmounted() {
    this.$store.commit("SET_ALL_LIST", []);
  },
  methods: {
    getLists() {
      let payload = {
        clubId: this.clubId,
        SearchText: "",
        entityType: this.$route.query.type || 1,
        exceptTemplateId: this.$route.query.template_id,
        limit: 999999999,
        exceptInactiveLists: true,
      };
      this.$store.dispatch("getLists", payload);
    },

    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    addMemberHandler() {
      if (+this.$route.query.type == 3) {
        this.addMembersForVoucherCampaign();
      }

      if (+this.$route.query.type == 2) {
        this.addMembersForEmailCampaign();
      }

      if (+this.$route.query.type == 1) {
        this.addMembersForDigitalpass();
      }
    },
    addMembersForEmailCampaign() {
      const lists = this?.membersData?.items?.filter((item) =>
        this.selectedItems.includes(item.id)
      );
      this.onConfirm(lists);
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },

    addMembersForDigitalpass() {
      var payload = {
        clubId: this.$route.params.id,
        templateId: +this.$route.query.template_id,
        isAll: this.forceSelectAll,
        listIds: this.selectedItems,
        exceptUserIds: this.rejectedItems,
      };

      this.$store.commit("LOADING_STATUS", true);
      var payloadGetResipients = {
        clubId: payload.clubId,
        templateId: payload.templateId,
      };
      addListMembersToTemplateService(payload)
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Audiences have been added from the selected lists.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          this.$store.dispatch("getRecipients", payloadGetResipients);
        })
        .catch((error) => {
          ;
        });
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    addMembersForVoucherCampaign() {
      let payload = {
        clubId: this.$route.params.id,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
        listIds: this.selectedItems,
      };

      this.$store.commit("LOADING_STATUS", true);
      const payloadForGetRecipients = {
        clubId: payload.clubId,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
      };
      addListMembersToVoucherCampaignService(payload)
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Audiences have been added from the selected lists.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          this.$store.dispatch("getVoucherRecipients", payloadForGetRecipients);
        })
        .catch((error) => {
          ;
        });

      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    modalHandler(data) {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },

    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      ;
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                ;
                ;
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            ;
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                ;
                ;
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            ;
            this.selectedItems = selectedData;
          }
        }
      }
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },
    onSearch(e) {
      this.isOnSearching = true;
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);

      // let payload = new GetMembersModel();
      // payload.clubId = this.clubId;
      // payload.searchQuery = this.searchWord;
      // payload.entityType = this.$route.query.type;
      // payload.exceptTemplateId = this.$route.query.template_id;
      // payload.exceptInactiveLists = true;

      let payload = {
        clubId: this.$route.params.id,
        searchQuery: this.searchWord,
        entityType: this.$route.query.type,
        limit: 999999999,
        exceptTemplateId: this.$route.query.template_id,
        exceptInactiveLists: true
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getLists", payload);
      }, 800);
    },
    dataStateChange: function (event) { },
    deleteMember() { },
    showNotification(message, type) {
      ;
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
};
</script>

<style></style>
