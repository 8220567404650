<template src="./MapDataToDigitalPassModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../../common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import modal from "../../../common/AppModal/AppModal.vue";
import PassMapModel from "../../../../models/digitalPass/PassMapModel";
import MapDataToPassModel from "../../../../models/digitalPass/MapDataToPassModel";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import { importDigitalPassMappedDataService } from "../../../../services/clubDetails/manageRecipients.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import AppDropdownGroup from "../../../common/AppDropdownGroup/AppDropdownGroup";

export default {
  components: {
    Tooltip,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    dropdownlist: DropDownList,
    modal,
    AppDropdownGroup,
  },
  props: {
    documentId: "",
    userIds: [],

    title: {
      type: String,
      default: "No Title",
    },
    templateType: {
      type: String,
      default: "4",
    },
  },
  data: function () {
    return {
      mapConfig: [],
      clubId: this.$route.params.id,
      memberImportModalVisible: false,
      closeMapModal: true,
      searchData: "",
      dValue: "",
      canSave: true,
      emailError: "Mapping is required",
      x: 0,
      dataOptions: [
        {
          label: "[DC]",
          options: [
            { value: "batman", label: "[Batman]" },
            { value: "robin", label: "[robin]" },
            { value: "joker", label: "[joker]" },
          ],
        },
        {
          label: "[Marvel]",
          options: [
            { value: "ironman", label: "[Iron Man]" },
            { value: "spiderman", label: "[Spiderman]" },
            { value: "captainamerica", label: "[Captain America]" },
          ],
        },
      ],
    };
  },
  computed: {
    columns() {
      var result = this.$store.state.manageRecipients.columnList;

      result = result.filter((x) => x != this.dValue);

      return result;
    },
    custom_fields() {
      var result = this.$store.state.manageRecipients.customFields;
      return result.data;
    },
    googlePassattributes() {
      var attributes = this.$store.state.manageRecipients.attributeList;
      attributes = attributes
        ?.filter((x) => x.passType == "Google" && x.label && x.mappable)
        .map((y) => ({
          name: y.name,
          code: y.code,
          label: y.label,
          isRequired: y.isRequired,
          errorMessage: "",
        }));
      return attributes;
    },
    applePassattributes() {
      var attributes = this.$store.state.manageRecipients.attributeList;
      attributes = attributes
        ?.filter((x) => x.passType == "Apple" && x.label && x.mappable)
        .map((y) => ({
          name: y.name,
          code: y.code,
          label: y.label,
          isRequired: y.isRequired,
          errorMessage: "",
        }));
      return attributes;
    },
    digitalPassattributes() {
      var attributes = this.$store.state.manageRecipients.attributeList;
      var emailObj = [
        {
          name: "Email",
          code: "email",
          passType: "Common",
          label: "Email",
          mappable: true,
          isRequired: true,
        },
      ];
      attributes = emailObj.concat(attributes);
      attributes = attributes
        ?.filter((x) => x.passType == "Common")
        .map((y) => ({
          name: y.name,
          code: y.code,
          label: y.label,
          isRequired: y.isRequired,
          errorMessage: "",
        }));
      return attributes;
    },
    attributeMap() {
      return this.$store.state.manageRecipients.attributeMap;
    },
  },
  mounted() {
    this.getColumns(this.documentId);
    this.getAttributes(this.$route.query.template_id);
    this.googlePassattributes;
    this.getAttributeMap();
  },

  methods: {
    defaultValue(code) {
      var mapFrom = this.attributeMap
        ?.filter((x) => x.column === code)
        .map((x) => x.mapFrom)[0];

      if (mapFrom) {
        var map = new PassMapModel();
        map.column = code;
        map.mapFrom = mapFrom;
        this.mapConfig.push(map);
      }

      return mapFrom ? mapFrom : "Select Column";

      // var mapFrom;

      // var ckWithMetaColumns;

      // for (const x of this.attributeMap) {
      //   if (x.column !== code) continue;
      //   mapFrom = x.mapFrom;
      // }
      // for(this.x; this.x <=1; this.x++) {
      //    
      //   ckWithMetaColumns = this.columns.includes(mapFrom);
      // }
      // ;

      // var mapFrom = this.attributeMap
      //   ?.filter((x) => x.column === code)
      //   .map((x) => x.mapFrom)[0];

      // var result = this.columns.includes(mapFrom)

      // ;
      // if (result) {

      //   if (result) {
      //     var map = new PassMapModel();
      //     map.column = code;
      //     map.mapFrom = mapFrom;
      //     this.mapConfig.push(map);
      //   }
      //   if (!result && mapFrom) {
      //     return "Select Column";
      //   }
      //   // else if(!result){
      //   //   return "Select Column"
      //   // }
      //   else if (result || mapFrom) {
      //     return mapFrom;
      //   }
      // }
    },
    getAttributeMap() {
      var payload = {
        clubId: this.$route.params.id,
        templateId: this.$route.query.template_id,
      };
      this.$store.dispatch("getAttributeMap", payload);
    },
    onOpen(e) {
      this.dValue = e.value;
      this.searchData = "";
      this.getColumns(this.documentId);
      this.getAttributes(this.$route.query.template_id);
    },
    onClose() {
      this.searchData = "";
    },
    cancelModal() {
      this.$store.dispatch("importCloseModal");
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.manageRecipients.columnModifier;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_COLUMNS", this.searchData);
    },
    filterData(filter) {
      const data = this.columns.slice();
      return filterBy(data, filter);
    },
    getColumns(id) {
      let payload = {
        documentId: id,
        clubId: this.clubId,
        entityType: 1,
      };
      let getPayload = {
        clubId: this.$route.params.id,
        documentId: id
      }
      this.$store.dispatch("getPassColumns", getPayload);
      this.$store.dispatch("getAudienceCustomFields", payload);
    },
    getAttributes(id) {
      var payload = {
        clubId: +this.clubId,
        templateId: this.$route.query.template_id,
      };
      // this.$store.dispatch("getPassTypeAttributes", id);
      this.$store.dispatch("getPassTypeAttributes", payload);
    },
    getCustomFieldSourceByValue(val) {
      let custom_fields = this.custom_fields;
      for (let i in custom_fields) {
        let searchHandler = custom_fields[i].options;
        for (let j in searchHandler) {
          if (searchHandler[j].value === val) {
            return searchHandler[j].source;
          }
        }
      }
    },
    handleMap(item, e) {
      this.emailError = "";
      let source = this.getCustomFieldSourceByValue(e);
      this.mapConfig = this.mapConfig.filter((x) => x.column != item);
      if (e != "Select Column") {
        var map = new PassMapModel();
        map.column = item;
        map.mapFrom = e;
        map.source = source;
        this.mapConfig.push(map);
      }
      if (this.mapConfig.filter((x) => x.column == "email").length) {
        this.canSave = true;
      }
      ;
    },
    // func(code) {
    //   var result = this.mapConfig.filter(
    //     (x) => x.column == code && x.mapFrom == ""
    //   );
    //   return false;
    // },
    saveData() {
      this.canSave = true;

      var result = this.mapConfig.filter((x) => x.column == "email");

      if (!result.length) {
        this.canSave = false;
      }

      if (!this.canSave) {
        return;
      }
      // var dp  = this.digitalPassattributes.filter(x => x.isRequired && x)

      let payload = new MapDataToPassModel();
      payload.clubId = +this.clubId;
      payload.templateId = this.$route.query.template_id;
      payload.documentId = this.documentId;
      payload.userIds = this.userIds;
      payload.mappingConfig = this.mapConfig;
      payload.entityType = 1;

      this.$emit("closeAllModals");
      this.$emit("closeAllModalsAdd");

      this.$store.dispatch("importCloseModal");

      this.$store.commit("LOADING_STATUS", true);

      var payloadGetResipients = {
        clubId: payload.clubId,
        templateId: payload.templateId,
      };
      importDigitalPassMappedDataService(payload)
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message =
            "Data for digital pass has been imported successfully.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          this.$store.dispatch("getRecipients", payloadGetResipients);
        })
        .catch((error) => {
          this.$store.commit("LOADING_STATUS", false);
          ;
          var model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message =
            "Something went wrong with uploaded file, please upload a valid file.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 4000);
        });

      // this.$store.dispatch("importDigitalPassMappedData", payload);

      this.$router.push({
        name: "AddRecipients",
        query: {
          template_id: this.$route.query.template_id,
          templateType: this.$route.query.templateType,
          title: this.$route.query.title,
        },
      });
    },
    modalHandler(data) {
      this.$emit("closeMapModal");
      this.$emit("closeMapModalAdd");
    },
  },
};
</script>
