<template src="./AddRecipients.html"></template>

<script>
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";
import SelectMemberModal from "../selectMemberModal/SelectMemberModal.vue";
import SelectAnAudienceListModal from "@/components/common/selectAnAudienceListModal/SelectAnAudienceListModal.vue";
import UploadDataForDigtalPassModal from "../uploadDataForDigtalPassModal/UploadDataForDigtalPassModal.vue";
import MapDataToDigitalPassModal from "../mapDataToDigitalPassModal/MapDataToDigitalPassModal";
import ManageRecipientsTable from "../manageRecipientsTable/ManageRecipientsTable";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownButton } from "@progress/kendo-vue-buttons";
export default {
  components: {
    buttongroup: ButtonGroup,
    kbutton: Button,
    "k-input": Input,
    SelectMemberModal: SelectMemberModal,
    UploadDataForDigtalPassModal: UploadDataForDigtalPassModal,
    MapDataToDigitalPassModal: MapDataToDigitalPassModal,
    modal,
    ManageRecipientsTable,
    AppSnackBar,
    "app-loader": AppLoader,
    Breadcrumb,
    SelectAnAudienceListModal,
    DropDownButton,
  },
  props: {
    id: {
      type: String,
    },
    template_id: {
      type: String,
      default: "0",
    },
    title: {
      type: String,
      default: "No Title",
    },
    templateType: {
      type: Object,
    },
  },
  data() {
    return {
      itemCode: "allDigitalPass",
      moduleCode: "digitalPass",
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
      },
      modalVisible: false,
      isSelectModalOpen: false,
      isUploadModalOpen: false,
      isMapDataModalOpen: false,
      documentId: 0,
      selectedMembers: [],
      clubId: this.$route.params.id,
      addAudienceDropDown: [{ id: 0, text: "Add Audience" }],
      isSearchingValue: false,
      typeOfAction: +this.$route.query.type,
    };
  },
  mounted() {

    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.getRecipient();
  },
  computed: {
    membersData() {
      return this.$store.state.manageRecipients.setRecipients;
    },
    isSearchingOrFiltering() {
      var result =
        this.$store.state.manageRecipients.searchQuery ||
        this.$store.state.manageRecipients.passStatus ||
        this.$store.state.manageRecipients.emailStatus;

      return result;
    },
    isShowMsg() {
      return this.$store.state.manageRecipients.showMsg;
    },
    showMsg() {
      return this.$store.state.manageRecipients.status;
    },
    clubListLoading() {
      return this.$store.state.manageRecipients.loading;
    },
    isSearching() {
      return this.$store.state.manageRecipients.searchQuery;
    },
    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
    showSuccessMsg() {
      return this.$store.state.manageRecipients.successStatus;
    },
    showErrorMsg() {
      return this.$store.state.manageRecipients.errorStatus;
    },
    isShowSuccessMsg() {
      return this.$store.state.manageRecipients.showSuccessMsg;
    },
    isShowErrorMsg() {
      return this.$store.state.manageRecipients.showErrorMsg;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    navigateToEditPass() {
      this.$router.push({
        name: "EditPass",
        query: {
          template_id: this.$route.query.template_id,
          templateType: this.$route.query.templateType,
          title: this.$route.query.title,
        },
        params: {
          fromManageRecipients: true,
          typeOfAction: this.typeOfAction,
        },
      });
    },
    modalHandler(data) {
      this.modalVisible = data;
    },
    onItemClick() {
      this.openModal();
    },
    openModal() {
      this.modalVisible = true;
      this.isSelectModalOpen = true;
      this.isUploadModalOpen = false;
      this.isMapDataModalOpen = false;
    },
    openModalForAddAudienceList() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
      this.$store.commit("SET_LOADER", true);
    },
    openUploadDataModal(event) {
      this.isSelectModalOpen = false;
      this.isUploadModalOpen = true;
      this.isMapDataModalOpen = false;
    },
    openMapDataModal(event) {
      this.documentId = event;
      this.isUploadModalOpen = false;
      this.isMapDataModalOpen = true;
    },
    closeSelectMemberModal() {
      this.isSelectModalOpen = false;
    },
    closeUploadDataModal() {
      this.isUploadModalOpen = false;
      this.isSelectModalOpen = true;
    },
    closeMapModal() {
      this.isMapDataModalOpen = false;
      this.isUploadModalOpen = true;
    },
    closeAllModals() {
      this.isSelectModalOpen = false;
      this.isUploadModalOpen = false;
      this.isMapDataModalOpen = false;
    },
    toDigitalPassTemp() {
      this.$router.push({
        name: "DigitalPass",
        query: {
          type: "1",
        },
      });
    },
    selectedMembersList(members) {
      this.selectedMembers = members;
    },
    uploadedFileForDigitalPass(file) { },
    getRecipient() {
      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
      };
      this.$store.dispatch("getRecipients", payload);
    },

    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>

<style></style>
