<modal :class="'max-height-100-145'" title="Map Data to Digital Pass" :handler="closeMapModal"
  :cssClass="'c-modal-lg map-data-modal'" @modalHandler="modalHandler">
  <template v-slot:content>
    <div>
      <div class="px-15 px-7-sm">
        <div class="row mb-3 drop-shadow p-12">
          <div class="col-5 d-flex align-items-center ps-1">
            <span class="pe-2 d-block">Digital Pass Fields</span>
            <Tooltip :open-delay="100" :position="'top'" :anchor-element="'target'"
              :className="'import-member-tooltip'">
              <div :style="{'text-align': 'center'}">
                <span class="k-icon k-i-information"
                  title="The system columns are general information of a member required to be registered on system."></span>
              </div>
            </Tooltip>
          </div>
          <div class="col-7 d-flex align-items-center">
            <span class="pe-2 d-block">Columns in your files</span>
            <Tooltip :open-delay="100" :position="'top'" :anchor-element="'target'"
              :className="'import-member-tooltip'">
              <div :style="{'text-align': 'center'}">
                <span class="k-icon k-i-information"
                  title="Select the columns from your uploaded file in order to map data to system columns."></span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>


      <!--Digital Pass Field-->
      <!-- <div class="d-flex align-items-center bg-gray px-16 py-9 my-12">
        <span class="pe-2 d-block font-16 font-w-700">Digital Pass Field</span>
        <Tooltip :open-delay="100" :position="'top'" :anchor-element="'target'" :className="'import-member-tooltip'">
          <div :style="{'text-align': 'center'}">
            <span class="k-icon k-i-information" title="Select the columns from your uploaded file in order to map data to system columns."></span>
          </div>
        </Tooltip>
      </div> -->
      <div class="px-15">
        <div v-for="(item, index) in digitalPassattributes" :key="index" class="row text-start">
          <!-- <div class="d-flex align-items-center bg-gray px-16 py-9 my-12">
            <span class="pe-2 d-block font-16 font-w-700">Digital Pass Field</span>
            <span class="k-icon k-i-warning"></span>
          </div> -->

          <div class="mb-4 col-5 align-self-center">
            <label>{{item.label}} {{item.label == 'Email' ? '*' : ''}}</label>
          </div>

          <div class="col-7">
            <div class="form-field pb-4">
              <!-- <dropdownlist
              @open="onOpen($event)"  
              @close="onClose"
              :class="'mb-24'"
              :data-items="columns"
              :filterable="true"
              filter=""
              @filterchange="filterChange"
              :value="digitalPassattributes[key]"
              @change="handleMap(item.code, $event)"
              :default-item="defaultValue(item.code)"
            >
            </dropdownlist> -->
              <AppDropdownGroup :placeholder="'Select Data'" :close-on-select="true" :searchable="true"
                :options="custom_fields" @change="handleMap(item.code, $event)" />
              <div class="error-msg" v-if="!canSave && item.label == 'Email'" @keyup="emailError=''">{{emailError}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- :default-item="defaultValue(item.code)" -->
      <!--For Google Pass-->
      <div class="d-flex align-items-center bg-gray px-16 py-9 my-12">
        <span class="pe-2 d-block font-16 font-w-700">For Google Pass</span>
        <Tooltip :open-delay="100" :position="'top'" :anchor-element="'target'" :className="'import-member-tooltip'">
          <div :style="{'text-align': 'center'}">
            <span class="k-icon k-i-information"
              title="Select the columns from your uploaded file in order to map data to system columns."></span>
          </div>
        </Tooltip>
      </div>

      <div class="px-15">
        <div v-for="(item, index) in googlePassattributes" :key="index" class="row text-start">
          <div class="mb-4 col-5 align-self-center">
            <label>{{item.label}}</label>
          </div>

          <div class="col-7">
            <!-- <dropdownlist
              @open="onOpen($event)" 
              @close="onClose"
              :class="'mb-4'"
              :data-items="columns"
              :filterable="true"
              filter=""
              @filterchange="filterChange"
              :value="googlePassattributes[key]"
              @change="handleMap(item.code, $event)"
              :default-item="defaultValue(item.code)"
            >
            </dropdownlist> -->
            <AppDropdownGroup :placeholder="'Select Data'" :close-on-select="true" :searchable="true"
              :options="custom_fields" @change="handleMap(item.code, $event)" />
          </div>
        </div>
      </div>


      <!--For Apple Pass-->
      <div class="d-flex align-items-center bg-gray px-16 py-9 my-12">
        <span class="pe-2 d-block font-16 font-w-700">For Apple Pass</span>
        <Tooltip :open-delay="100" :position="'top'" :anchor-element="'target'" :className="'import-member-tooltip'">
          <div :style="{'text-align': 'center'}">
            <span class="k-icon k-i-information"
              title="Select the columns from your uploaded file in order to map data to system columns."></span>
          </div>
        </Tooltip>
      </div>

      <div class="px-15">
        <div v-for="(item, index) in applePassattributes" :key="index" class="row text-start">
          <div class="mb-4 col-5 align-self-center">
            <label>{{item.label}}</label>
          </div>

          <div class="col-7">
            <!-- <dropdownlist
              @open="onOpen($event)" 
              @close="onClose"
              :class="'mb-4'"
              :data-items="columns"
              :filterable="true"
              filter=""
              @filterchange="filterChange"
              :value="applePassattributes[key]"
              @change="handleMap(item.code, $event)"
              :default-item="defaultValue(item.code)"
            >
            </dropdownlist> -->
            <AppDropdownGroup :placeholder="'Select Data'" :object="true" :close-on-select="true" :searchable="true"
              :options="custom_fields" @change="handleMap(item.code, $event)" />
          </div>
        </div>
      </div>

    </div>
  </template>
  <template v-slot:footer>
    <app-loader v-if="loading" class="form-spinner"></app-loader>
    <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
    <kbutton :theme-color="'primary'" @click="saveData" :class="'flex-none'">Import Data</kbutton>
  </template>
</modal>