<template src='./DataUnavailableUserModal.html'></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "../../../common/AppLoader/AppLoader.vue";
import modal from "../../../common/AppModal/AppModal.vue";
export default {
  props: {
    users: {
      type: Array,
      default: [],
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
  },
  computed:{
      memberList(){
        var result = this.users.map(x => x.name).join(', ')
        return result
      }
  },
  methods: {
    modalHandler(data) {
      this.$emit("closeDataUnavailableUserModal");
    },
  },
};
</script>

<style>
</style>