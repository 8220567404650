<modal title="Modal Details" :handler="true" :cssClass="'modal-xxl'" @modalHandler="modalHandler">
    <template v-slot:content>
        <div class="k-form-field">
            <label>Name</label>
            <k-input :placeholder="'Email Address'" />
        </div>
        <div class="k-form-field">
            <label>Member Id</label>
            <k-input :placeholder="'Email Address'" />
        </div>
        <div class="k-form-field">
            <label>Set Number</label>
            <k-input :placeholder="'Email Address'" />
        </div>
        <div class="k-form-field">
            <label>Details</label>
            <k-input :placeholder="'Email Address'" />
        </div>

    </template>
    <template v-slot:footer>
        <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
        <kbutton :theme-color="'primary'" :class="'flex-none'" @click="callBackSelectMember">Import Data</kbutton>
    </template>
</modal>