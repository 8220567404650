<template src="./ManageRecipientsTable.html"></template>
<style lang="scss">
@import "./ManageRecipientsTable.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import UploadDataForDigtalPassModal from "../uploadDataForDigtalPassModal/UploadDataForDigtalPassModal.vue";
import MapDataToDigitalPassModal from "../mapDataToDigitalPassModal/MapDataToDigitalPassModal";
import SelectMemberModal from "../selectMemberModal/SelectMemberModal.vue";
import RecipientsMemberDetails from "../recipientsMemberDetails/RecipientsMemberDetails";
import RecipientsModel from "../../../../models/digitalPass/RecipientsModel";
import DataUnavailableUserModal from "../dataUnavailableUserModal/DataUnavailableUserModal.vue";
import ManageRecipientsDeleteModal from "./manageRecipientsDeleteModal/ManageRecipientsDeleteModal.vue";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { sentDigitalPassService } from "../../../../services/clubDetails/manageRecipients.service";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AppPopUp from "../../../common/AppPopUp/AppPopUp.vue";
import SelectAnAudienceListModal from "@/components/common/selectAnAudienceListModal/SelectAnAudienceListModal.vue";

export default {
  name: "ManageRecipientsTable",
  props: {
    title: {
      type: String,
      default: "No Title",
    },
    templateType: {
      type: Object,
    },
    isBreadCrumb: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    buttongroup: ButtonGroup,
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    UploadDataForDigtalPassModal,
    MapDataToDigitalPassModal,
    SelectMemberModal,
    RecipientsMemberDetails,
    DataUnavailableUserModal,
    ManageRecipientsDeleteModal,
    DropDownButton,
    AppPopUp,
    SelectAnAudienceListModal,
    GridNoRecords,
  },

  data() {
    return {
      itemCode: "allDigitalPass",
      moduleCode: "digitalPass",
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      isUploadModalOpen: false,
      isMapDataModalOpen: false,
      isViewDetailsModalOpen: false,
      isCkUserDataAvailableModalOpen: false,
      addFromClubmodalVisible: false,
      addFromClubIsSelectModalOpen: false,
      addFromClubisUploadModalOpen: false,
      addFromClubisMapDataModalOpen: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      moreOptionDropdownitems: [
        {
          text: "Import Data For Pass",
          dropId: 1,
        },
        {
          text: "Download Report",
          dropId: 2,
        },
      ],
      selectedItems: [],
      documentIdPass: "",
      passStatuses: [
        { id: 0, text: "All" },
        { id: 1, text: "Generated" },
        { id: 2, text: "Not Generated" },
      ],
      passStatusValue: { id: 0, text: "All" },
      addAudienceDropDown: [{ id: 0, text: "Add Audience" }],

      emailStatuses: [
        { id: 1, text: "All", value: "All" },
        { id: 2, text: "Sent", value: "Sent" },
        { id: 3, text: "Not Sent", value: "NotSent" },
        // { id: 4, text: "Delivered", value: "Delivered" },
        // { id: 5, text: "Not Delivered", value: "NotDelivered" },
        // { id: 6, text: "Opened", value: "Opened" },
        // { id: 7, text: "Clicked", value: "Clicked" },
      ],
      emailStatusValue: { id: 1, text: "All", value: "All" },
      selectedMembers: [],
      selectedMemberList: [],
      passCell: "passCell",
      emailCell: "emailCell",
      timer: null,
      forceSelectAll: false,
      allChecked: false,
    };
  },
  unmounted() {
    this.$store.commit("SET_RECIPIENTS", []);
    this.selectedItems = [];
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
  },
  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length == this.membersData?.items?.length &&
        this.membersData?.items?.length != 0
      );
    },

    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
        },
        {
          field: "name",
          title: "Name",
        },
        {
          field: "email",
          title: "Email",
        },
        {
          field: "passStatus",
          title: "Pass Status",
          cell: "passCell",
        },
        {
          field: "emailStatus",
          title: "Email Status",
          cell: "emailCell",
        },
      ];
    },

    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.manageRecipients.setRecipients.items;
        ;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }
        return this.$store.state.manageRecipients.setRecipients;
      } else {
        let membersData = this.$store.state.manageRecipients.setRecipients.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        ;
        return this.$store.state.manageRecipients.setRecipients;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      return this.membersData ? this.membersData.offset : 0;
    },
    isDeleteOpen() {
      return this.$store.state.manageRecipients.visible;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    digitalPassDeleteSuccess() {
      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
      };
      this.timer = setTimeout(() => {
        this.$store.dispatch("getRecipients", payload);
      }, 800);
    },
    getDropId(e) {
      if (e == 1) {
        this.openModal();
      }
      if (e == 2) {
        this.downloadFile();
      }
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                ;
                ;
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        ;
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                ;
                ;
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
            ;
          }
        }
      }
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.$store.commit("LOADING_STATUS", true);
      var payload = {
        clubId: this.clubId,
        limit: this.take,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getRecipients", payload);
      }, 800);
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: +this.clubId,
        limit: event.data.take,
        offset: event.data.skip,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };

      this.$store.dispatch("getRecipients", payload);
    },

    openModal() {
      this.isUploadModalOpen = true;
    },
    closeUploadDataModal() {
      this.isUploadModalOpen = false;
    },
    openMapDataModal(e) {
      this.documentIdPass = e;
      this.isMapDataModalOpen = true;
    },
    closeMapModal() {
      this.isUploadModalOpen = true;
      this.isMapDataModalOpen = false;
    },
    closeAllModals() {
      this.isUploadModalOpen = false;
      this.isMapDataModalOpen = false;
    },
    onItemClick() {
      this.addFromClubopenModal();
    },
    openModalForAddAudienceList() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    addFromClubopenModal() {
      this.addFromClubmodalVisible = true;
      this.addFromClubIsSelectModalOpen = true;
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = false;
    },
    addFromClubOpenUploadDataModal(event) {
      this.addFromClubIsSelectModalOpen = false;
      this.addFromClubisUploadModalOpen = true;
      this.addFromClubisMapDataModalOpen = false;
    },
    addFromClubopenMapDataModal(event) {
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = true;
    },
    callBackDataForDigitalPassDocId(event) {
      this.documentIdPass = event;
    },
    addFromClubcloseSelectMemberModal() {
      this.addFromClubIsSelectModalOpen = false;
    },
    addFromClubcloseUploadDataModal() {
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubIsSelectModalOpen = true;
    },
    addFromClubcloseMapModal() {
      this.addFromClubisMapDataModalOpen = false;
      this.addFromClubisUploadModalOpen = true;
    },
    addFromClubcloseAllModals() {
      this.addFromClubIsSelectModalOpen = false;
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = false;
    },
    deleteRecipients() {
      this.$store.commit("SET_MODAL_VISIBLE", true);
      this.selectedMemberList = [];
    },
    sendRecipients() {
      var ck = this.selectedMemberList.filter((x) => !x.dataAvailable);
      if (ck.length > 0) {
        this.isCkUserDataAvailableModalOpen = true;
        return;
      }
      var payload = {
        clubId: +this.clubId,
        ids: this.selectedItems,
        templateId: this.$route.query.template_id,
      };

      var payloadOriginal = {
        clubId: payload.clubId,
        ids: payload.ids,
      };

      var payloadGetResipients = {
        clubId: payload.clubId,
        templateId: payload.templateId,
      };

      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
        limit: this.take,
      };

      this.$store.commit("LOADING_STATUS", true);
      sentDigitalPassService(payloadOriginal)
        .then((response) => {
          this.$store.dispatch("getRecipients", payload);

          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message =
            "Digital Pass emails have been sent to selected audiences.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 3000);
        })
        .catch((error) => {
          ;
        });

      // this.$store.dispatch("sentDigitalPass", payload);
    },
    closeDataUnavailableUserModal() {
      this.isCkUserDataAvailableModalOpen = false;
    },
    getRecipient() {
      var payload = {
        id: this.clubId,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };
      this.$store.dispatch("getRecipients", payload);
    },
    handlePassStatusValue(e) {
      this.passStatusValue = e.value;
      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };
      this.$store.dispatch("getRecipients", payload);
    },
    handleEmailStatusValue(e) {
      this.emailStatusValue = e.value;
      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
        searchQuery: this.searchWord,
        emailStatus: this.emailStatusValue?.value,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };
      this.$store.dispatch("getRecipients", payload);
    },
    closeMemberDetailsModal() {
      this.isViewDetailsModalOpen = false;
    },
    downloadFile() {
      var payload = {
        clubId: +this.clubId,
        templateId: this.$route.query.template_id,
      };
      this.$store.dispatch("recipientsExport", payload);
    },
    selectedMembersList(members) {
      this.selectedMembers = members;
    },
  },
};
</script>
