<template src="./ManageRecipientsDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../common/AppModal/AppModal";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      title: "",
      message: "",
    };
  },
  mounted() {
    this.title = +this.$route.query.type == 3 ? "Delete Recipients" : "Delete Audience";
    this.message =
      +this.$route.query.type == 3
        ? "Are you sure you want to delete selected recipient(s) from this voucher recipient list?"
        : "Are you sure you want to delete selected audience(s) from this digital pass list?";
  },
  computed: {
    modalVisible() {
      return this.$store.state.manageRecipients.visible;
    },
  },
  methods: {
    cancelModal() {
      this.$store.commit("SET_MODAL_VISIBLE", false);
    },

    deletePassTemplate() {
      if (this.$route.query.type == 3) {
        var payload = {
          clubId: this.$route.params.id,
          ids: this.selectedItems,
          voucherCampaignId: this.$route.query.voucherCampaignId,
        };

        this.$store.dispatch("deleteVoucherRecipients", payload);
        this.$store.commit("SET_MODAL_VISIBLE", false);
      } else {
        var payload = {
          clubId: this.$route.params.id,
          ids: this.selectedItems,
          templateId: this.$route.query.template_id,
        };
        this.$store.dispatch("deleteRecipients", payload);
        this.$store.commit("SET_MODAL_VISIBLE", false);
        this.$emit("digitalPassDeleteSuccess");
      }
    },
    modalHandler(data) {
      this.$store.commit("SET_MODAL_VISIBLE", false);
    },
  },
};
</script>

<style></style>
