<template src="./RecipientsMemberDetails.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import AppLoader from "../../../common/AppLoader/AppLoader.vue";
import modal from "../../../common/AppModal/AppModal.vue";

export default {
  components: {
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    "app-loader": AppLoader,
    modal,
  },

  methods: {
    modalHandler(data) {
      this.$emit("closeMemberDetailsModal");
    },
  },
};
</script>

<style>
</style>